body {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f0f0f0;
}

#game-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    max-width: 100%;
    max-height: 100%;
    height: 400px;
}

#card-area {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-grow: 1;
    padding-left: 5px;
    padding-right: 5px;
}

#center-cards {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-grow: 1;
}

.center-card-container {
    margin: 0;
    box-sizing: border-box;
}

.dots {
    width: 25px;
    height: 150px;
}

.card {
    width: 100px;
    height: 150px;
    cursor: pointer;
    border-radius: 10px;
    margin-left: 5px;
    margin-right: 5px;
}

#card-tray {
    display: flex;
    overflow-x: auto;
    max-width: 100%;
}

.tray-card {
    width: 70px;
    min-width: 70px;
    height: 100px;
    cursor: pointer;
}

.disabled > .tray-card {
    cursor: not-allowed;
    opacity: 0.5;
}

.bottom-button {
    width: 100%;
    border: none;
    padding: 10px;
    margin-top: 10px;
}