body {
  background-color: #f0f0f0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
}

#game-container {
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  height: 400px;
  max-height: 100%;
  display: flex;
}

#card-area {
  flex-grow: 1;
  justify-content: space-around;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
}

#center-cards {
  flex-grow: 1;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.center-card-container {
  box-sizing: border-box;
  margin: 0;
}

.dots {
  width: 25px;
  height: 150px;
}

.card {
  cursor: pointer;
  border-radius: 10px;
  width: 100px;
  height: 150px;
  margin-left: 5px;
  margin-right: 5px;
}

#card-tray {
  max-width: 100%;
  display: flex;
  overflow-x: auto;
}

.tray-card {
  cursor: pointer;
  width: 70px;
  min-width: 70px;
  height: 100px;
}

.disabled > .tray-card {
  cursor: not-allowed;
  opacity: .5;
}

.bottom-button {
  border: none;
  width: 100%;
  margin-top: 10px;
  padding: 10px;
}

/*# sourceMappingURL=index.9ec32b66.css.map */
